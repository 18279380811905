import React, { useState, useEffect, useRef } from 'react';
import {
  Text,
  TextInput,
  TouchableOpacity,
  View,
  ActivityIndicator,
  Platform,
} from 'react-native';

import { createTruck, updateTruck } from '../../../api/Settings';
import BasicModalContainer from '../../../common/components/basicmodal/BasicModalContainer';
import ModalHeader from '../../../common/components/basicmodal/ModalHeader';
import ModalBody from '../../../common/components/basicmodal/ModalBody';
import ModalFooter from '../../../common/components/basicmodal/ModalFooter';
import { msgStr } from '../../../common/constants/Message';
import { useAlertModal } from '../../../common/hooks';

import { truckModalstyles } from './styles/TruckModalStyle';
import NumericInput from '../../../common/components/formcomponents/NumericInput';

interface FormValues {
  TruckName: string;
  IsDeleted: boolean;
  HHI_Resort: string;
  LicensePlate: string;
  MaxCapacity: number;
  Ocean_1: string;
  PD_Pass: string;
  SP_Pass: string;
  SY_Pass: string;
}


const AddTruckModal = ({ isModalVisible, Truck, setUpdateTruckTrigger, closeModal }) => {
  const isUpdate = Truck ? true : false;
  const inputRef = useRef(null);

  const { showAlert } = useAlertModal();
  const [ValidMessage, setValidMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  // const [TruckNameTxt, setTruckNameTxt] = useState('');
  // const [TruckShortNameTxt, setTruckShortNameTxt] = useState('');
  // const [TruckBarcodeTxt, setTruckBarcodeTxt] = useState('');

  const [formValues, setFormValues] = useState<FormValues>({
    TruckName: '',
    IsDeleted: false,
    HHI_Resort: '',
    LicensePlate: '',
    MaxCapacity: 0,
    Ocean_1: '',
    PD_Pass: '',
    SP_Pass: '',
    SY_Pass: '',
  });

  const changeFormValue = (name, value) => {
    setFormValues(prev => ({
      ...prev,
      [name]:value,
    }))
  }


  useEffect(() => {
    if (isModalVisible) {
      if (Truck) {
        setFormValues({
          TruckName: Truck.TruckName || '',
          IsDeleted: Truck.IsDeleted || false,
          HHI_Resort: Truck.HHI_Resort || '',
          LicensePlate: Truck.LicensePlate || '',
          MaxCapacity: Truck.MaxCapacity || 0,
          Ocean_1: Truck.Ocean_1 || '',
          PD_Pass: Truck.PD_Pass || '',
          SP_Pass: Truck.SP_Pass || '',
          SY_Pass: Truck.SY_Pass || '',
        });
      } else {
        setFormValues({
          TruckName: '',
          IsDeleted: false,
          HHI_Resort: '',
          LicensePlate: '',
          MaxCapacity: 0,
          Ocean_1: '',
          PD_Pass: '',
          SP_Pass: '',
          SY_Pass: '',
        });
      }
    }
    setValidMessage('');
  }, [isModalVisible]);

  const AddButtonHandler = () => {
    if (!formValues.TruckName.trim()) {
      setValidMessage(msgStr('emptyField'));
      return;
    }

    setIsLoading(true);

    const payload:any = formValues;

    const handleResponse = (jsonRes, status) => {
      switch (status) {
        case 201:
          showAlert('success', jsonRes.message);
          setUpdateTruckTrigger(true);
          closeModal();
          break;
        case 409:
          setValidMessage(jsonRes.error);
          break;
        default:
          if (jsonRes && jsonRes.error) showAlert('error', jsonRes.error);
          else showAlert('error', msgStr('unknownError'));
          closeModal();
          break;
      }
      setIsLoading(false);
    };

    if (isUpdate) {
      payload.id = Truck.id;
      updateTruck(payload, (jsonRes, status) => {
        handleResponse(jsonRes, status);
      });
    } else {
      createTruck(payload, (jsonRes, status) => {
        handleResponse(jsonRes, status);
      });
    }
  };

  const closeModalhandler = () => {
    closeModal();
  };

  const checkInput = () => {
    if (!formValues.TruckName.trim()) {
      setValidMessage(msgStr('emptyField'));
    } else {
      setValidMessage('');
    }
  };

  return isModalVisible ? (
    <View style={{ position: 'absolute', width: '100%', height: '100%' }}>
      <BasicModalContainer>
        <ModalHeader
          label={'Truck'}
          closeModal={() => {
            closeModalhandler();
          }}
        />
        <ModalBody>
          <View style={{flexDirection:'row'}}>
            <View style={{marginRight:25}}>
              <Text style={styles.label}>Name</Text>
              <TextInput
                style={styles.input}
                placeholder="Name"
                value={formValues.TruckName}
                onChangeText={val=>changeFormValue('TruckName', val)}
                placeholderTextColor="#ccc"
                onBlur={checkInput}
              />
              {ValidMessage.trim() != '' && <Text style={styles.message}>{ValidMessage}</Text>}
              <Text style={styles.label}>HHI Resort</Text>
              <TextInput
                style={styles.input}
                placeholder="HHI Resort"
                value={formValues.HHI_Resort}
                onChangeText={val=>changeFormValue('HHI_Resort', val)}
                placeholderTextColor="#ccc"
              />
              <Text style={styles.label}>License Plate</Text>
              <TextInput
                style={styles.input}
                placeholder="License Plate"
                value={formValues.LicensePlate}
                onChangeText={val=>changeFormValue('LicensePlate', val)}
                placeholderTextColor="#ccc"
              />
              <Text style={styles.label}>Max Capacity</Text>
              <NumericInput
                validMinNumber={0}
                style={styles.input}
                placeholder="Max Capacity"
                value={formValues?.MaxCapacity?.toString()??''}
                onChangeText={val=>changeFormValue('max_capacity', val)}
                placeholderTextColor="#ccc"
              />
            </View>
            <View>
              <Text style={styles.label}>Ocean1</Text>
              <TextInput
                style={styles.input}
                placeholder="Ocean1"
                value={formValues.Ocean_1}
                onChangeText={val=>changeFormValue('Ocean_1', val)}
                placeholderTextColor="#ccc"
              />
              <Text style={styles.label}>PD Pass</Text>
              <TextInput
                style={styles.input}
                placeholder="PD Pass"
                value={formValues.PD_Pass}
                onChangeText={val=>changeFormValue('PD_Pass', val)}
                placeholderTextColor="#ccc"
              />
              <Text style={styles.label}>SP Pass</Text>
              <TextInput
                style={styles.input}
                placeholder="SP Pass"
                value={formValues.SP_Pass}
                onChangeText={val=>changeFormValue('SP_Pass', val)}
                placeholderTextColor="#ccc"
              />
              <Text style={styles.label}>SY Pass</Text>
              <TextInput
                style={styles.input}
                placeholder="SY Pass"
                value={formValues.SY_Pass}
                onChangeText={val=>changeFormValue('SY_Pass', val)}
                placeholderTextColor="#ccc"
              />
            </View>
          </View>
        </ModalBody>
        <ModalFooter>
          <View style={{ flexDirection: 'row' }}>
            <TouchableOpacity onPress={AddButtonHandler}>
              <Text style={styles.addButton}>{isUpdate ? 'Update' : 'Add'}</Text>
            </TouchableOpacity>
          </View>
        </ModalFooter>
      </BasicModalContainer>
      {isLoading && (
        <View style={styles.overlay}>
          <ActivityIndicator size="large" color="#0000ff" />
        </View>
      )}
    </View>
  ) : null;
};

const styles = truckModalstyles;

export default AddTruckModal;

import icon from './icon2.svg';
import icon2 from "./icon.svg";
import { Button, Settings } from "react-chatbotify";

export const settings:Settings = {
  notification: {
    // disabled: true,
  },
  tooltip:{
    mode: "NEVER"
  },
  chatButton: {
    icon: icon
  },
  header:{
    title: <span style={{fontSize:20, fontWeight:700,}}>BOD Assistant</span>,
    // avatar: icon2,
  },
  voice:{
    disabled: false,
  },
  footer: {
    text: '',
    buttons: [Button.EMOJI_PICKER_BUTTON]
  },
  chatHistory:{
    storageKey:"rcb-history",
    autoLoad: true,
  }
};
import React, { useEffect, useState } from 'react';
import { Text, TouchableHighlight, TouchableOpacity } from 'react-native';
import { FontAwesome5 } from '@expo/vector-icons';

import { getBlockDatesData, deleteBlockDate } from '../../../api/Settings';
import { msgStr } from '../../../common/constants/Message';
import { API_URL } from '../../../common/constants/AppConstants';
import { TextMediumSize } from '../../../common/constants/Fonts';
import { useAlertModal, useConfirmModal } from '../../../common/hooks';
import { BasicLayout, CommonContainer } from '../../../common/components/CustomLayout';
import { BOHButton, BOHToolbar } from '../../../common/components/bohtoolbar';
import { BOHTBody, BOHTD, BOHTDIconBox, BOHTDImageBox, BOHTH, BOHTH2, BOHTHead, BOHTR, BOHTable } from '../../../common/components/bohtable';

import AddBlockDateModal from './AddBlockDateModal';
import { formatDate3 } from '../../../common/utils/DateUtils';

const BlockDates = ({ navigation, openInventory }) => {

  const { showAlert } = useAlertModal();
  const { showConfirm } = useConfirmModal();

  const [tableData, setTableData] = useState([]);
  const [updateBlockDateTrigger, setUpdateBlockDateTrigger] = useState(true);

  const [isAddModalVisible, setAddModalVisible] = useState(false);
  const [selectedBlockDate, setSelectedBlockDate] = useState(null);

  const InitialWidths = [200, 100, 80, 50, 50];

  const openAddBlockDateModal = () => {
    setAddModalVisible(true);
    setSelectedBlockDate(null);
  };
  const closeAddBlockDateModal = () => {
    setAddModalVisible(false);
    setSelectedBlockDate(null);
  };
  const editBlockDate = (item) => {
    setSelectedBlockDate(item);
    setAddModalVisible(true);
  };
  
  useEffect(() => {
    if (updateBlockDateTrigger == true) getTable();
  }, [updateBlockDateTrigger]);

  const removeBlockDate = (id) => {
    showConfirm(msgStr('deleteConfirmStr'), () => {
      deleteBlockDate(id, (jsonRes, status, error) => {
        switch (status) {
          case 200:
            setUpdateBlockDateTrigger(true);
            showAlert('success', jsonRes.message);
            break;
          default:
            if (jsonRes && jsonRes.error) showAlert('error', jsonRes.error);
            else showAlert('error', msgStr('unknownError'));
            break;
        }
      });
    });
  };

  const getTable = () => {
    getBlockDatesData((jsonRes, status, error) => {
      switch (status) {
        case 200:
          setUpdateBlockDateTrigger(false);
          setTableData(jsonRes);
          break;
        case 500:
          showAlert('error', msgStr('serverError'));
          break;
        default:
          if (jsonRes && jsonRes.error) showAlert('error', jsonRes.error);
          else showAlert('error', msgStr('unknownError'));
          break;
      }
    });
  };

  const renderTableData = () => {
    const rows = [];
    if (tableData.length > 0) {
      tableData.map((item, index) => {
        let price = '';
        switch(item.option){
          case 0:
            price = 'Free';
            break;
          case 1:
            price = item.fixed_price;
            break;
          case 2:
            break;
        }
        rows.push(
          <BOHTR key={index}>
            <BOHTD width={InitialWidths[0]}>{item.title}</BOHTD>
            <BOHTD width={InitialWidths[1]}>{formatDate3(item.date)}</BOHTD>
            <BOHTD width={InitialWidths[2]} textAlign='right'>{item.duration}</BOHTD>
            <BOHTDIconBox width={InitialWidths[3]}>
              <TouchableOpacity
                onPress={() => {
                  editBlockDate(item);
                }}
              >
                <FontAwesome5 size={TextMediumSize} name="edit" color="black" />
              </TouchableOpacity>
            </BOHTDIconBox>
            <BOHTDIconBox width={InitialWidths[4]}>
              <TouchableOpacity
                onPress={() => {
                  removeBlockDate(item.id);
                }}
              >
                <FontAwesome5 size={TextMediumSize} name="times" color="black" />
              </TouchableOpacity>
            </BOHTDIconBox>
          </BOHTR>
        );
      });
    } else {
      <></>;
    }
    return <>{rows}</>;
  };

  return (
    <BasicLayout
      navigation={navigation}
      goBack={() => {
        openInventory(null);
      }}
      screenName={'BlockDates'}
    >
      <CommonContainer>
        <BOHToolbar>
          <BOHButton 
            label={'Add'}
            onPress={openAddBlockDateModal}/>
        </BOHToolbar>
        <BOHTable>
          <BOHTHead>
            <BOHTR>
              <BOHTH2 width={InitialWidths[0]}>{'Title'}</BOHTH2>
              <BOHTH2 width={InitialWidths[1]}>{'Date'}</BOHTH2>
              <BOHTH2 width={InitialWidths[2]}>{'Duration'}</BOHTH2>
              <BOHTH2 width={InitialWidths[3]}>{'Edit'}</BOHTH2>
              <BOHTH2 width={InitialWidths[4]}>{'DEL'}</BOHTH2>
            </BOHTR>
          </BOHTHead>
          <BOHTBody>
            {renderTableData()}
          </BOHTBody>
        </BOHTable>
      </CommonContainer>

      <AddBlockDateModal
        isModalVisible={isAddModalVisible}
        BlockDate={selectedBlockDate}
        setUpdateBlockDateTrigger={setUpdateBlockDateTrigger}
        closeModal={closeAddBlockDateModal}
      />
    </BasicLayout>
  );
};

export default BlockDates;

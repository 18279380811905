import { basePostAPICall, getAPICall, postAPICall } from './BaseAPI';

export const chatbotquery = async (payload, cb=(jR, s, e)=>{}) => {
  return await postAPICall('ai/query/', payload, cb);
}

export const getRecommendsByRandom = async (cb=(jR, s, e)=>{}) => {
  return await getAPICall('ai/getrecommendsbyrandom/', cb);
}

export const getBasicQuizzesData = async (cb = (jR, s, e)=>{}) => {
  return await getAPICall('ai/getbasicquizzesdata/',  cb);
}

export const createBasicQuiz = async (payload, cb=(jR, s, e)=>{}) => {
  return await postAPICall('ai/createbasicquiz', payload, cb);
};

export const updateBasicQuiz = async (payload, cb=(jR, s, e)=>{}) => {
  return await postAPICall('ai/updatebasicquiz', payload, cb);
};

export const deleteBasicQuiz = (id, cb=(jR, s, e)=>{}) => {
  const payload = { id };
  postAPICall('ai/deletebasicquiz', payload, cb);
}
import React, { useState } from 'react';
import { View, Text, TouchableOpacity, StyleSheet} from 'react-native';
import { ScrollView } from 'react-native-gesture-handler';

import TouchNavGroup from '../../common/components/navpanel/TouchNavGroup';
import BasicLayout from '../../common/components/CustomLayout/BasicLayout';

interface Props {
    navigation: any;
    initialItem?: string;
}

const Reports = ({navigation, initialItem }:Props) => {
    const [selectedItem, setSelectedItem] = useState<string>(initialItem || '');
    
    const handleItemClick = (itemName) => {
        setSelectedItem(itemName);
    };
    
    if(selectedItem) {
        switch (selectedItem) {
            case 'Bike Stand Sales Report': navigation.navigate('BikeStandSalesReport'); break;
            default:
                return (
                    <View style={{ marginTop: 20, paddingHorizontal: 10, paddingVertical:2, height: 28, justifyContent: 'center', flexDirection: 'row',}}>
                        <TouchableOpacity  onPress={() => handleItemClick(null)}>
                            <Text>{'< Back'}</Text>
                        </TouchableOpacity>
                        <Text style={{ fontSize: 28 }}>{selectedItem}</Text>
                    </View>
                );
        }
    };

    return (
        <BasicLayout
            navigation={navigation}
            screenName={"Reports"}
        >
            <ScrollView>
                <View style={styles.container}>
                    <TouchNavGroup sectionTitle="Reports" items={[
                        { title: "Bike Stand Sales Report", icon: 'map-marker-alt' },
                    ]} handleItemClick={handleItemClick} />
                </View>
            </ScrollView> 
        </BasicLayout>
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        padding: 20,
        width: '90%',
        maxWidth: 1000,
        margin: 'auto',
        marginTop: 40,
    },
});

export default Reports;

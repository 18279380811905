import React, { useEffect, useMemo, useState } from 'react';
import { TouchableOpacity } from 'react-native';
import { FontAwesome5 } from '@expo/vector-icons';

import { deleteBasicQuiz, getBasicQuizzesData } from '../../../api/Chatbot';
import { BasicLayout, CommonContainer } from '../../../common/components/CustomLayout';
import { BOHTBody, BOHTD, BOHTDIconBox, BOHTH2, BOHTHead, BOHTR, BOHTable } from '../../../common/components/bohtable';
import { BOHButton, BOHToolbar } from '../../../common/components/bohtoolbar';
import { msgStr } from '../../../common/constants/Message';
import { TextMediumSize } from '../../../common/constants/Fonts';
import { useAlertModal, useConfirmModal } from '../../../common/hooks';

import AddBasicQuizModal from './AddBasicQuizModal';

const BasicQuizzes = ({ navigation }) => {
  const { showAlert } = useAlertModal();
  const { showConfirm } = useConfirmModal();
  
  const [tableData, setTableData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [updateBasicQuizTrigger, setUpdateBasicQuizzesTrigger] = useState(true);
  const InitialWidths = [250, 160, 160, 160, 400, 50, 50];

  const [isAddModalVisible, setAddModalVisible] = useState(false);
  const [selectedBasicQuiz, setSelectedBasicQuiz] = useState(null);

  const openAddBasicQuizModal = () => {
    setAddModalVisible(true);
    setSelectedBasicQuiz(null);
  };
  const closeAddBasicQuizModal = () => {
    setAddModalVisible(false);
    setSelectedBasicQuiz(null);
  };
  const editBasicQuiz = (item) => {
    setSelectedBasicQuiz(item);
    setAddModalVisible(true);
  };

  useEffect(() => {
    if (updateBasicQuizTrigger == true) getTable();
  }, [updateBasicQuizTrigger]);

  const removeBasicQuiz = (id) => {
    showConfirm(msgStr('deleteConfirmStr'), () => {
      deleteBasicQuiz(id, (jsonRes, status, error) => {
        switch (status) {
          case 200:
            setUpdateBasicQuizzesTrigger(true);
            showAlert('success', jsonRes.message);
            break;
          default:
            if (jsonRes && jsonRes.error) showAlert('error', jsonRes.error);
            else showAlert('error', msgStr('unknownError'));
            break;
        }
      });
    });
  };

  const getTable = () => {
    setIsLoading(true);
    getBasicQuizzesData((jsonRes, status, error) => {
      switch (status) {
        case 200:
          setUpdateBasicQuizzesTrigger(false);
          setTableData(jsonRes);
          break;
        case 500:
          showAlert('error', msgStr('serverError'));
          break;
        default:
          if (jsonRes && jsonRes.error) showAlert('error', jsonRes.error);
          else showAlert('error', msgStr('unknownError'));
          break;
      }
    });
  };

  const renderTableData = useMemo(() => {
    const rows = [];
    if (tableData.length > 0) {
      tableData.map((item, index) => {
        rows.push(
          <BOHTR key={index}>
            <BOHTD width={InitialWidths[0]}>{item.quiz}</BOHTD>
            <BOHTD width={InitialWidths[1]}>{item.endpoint}</BOHTD>
            <BOHTD width={InitialWidths[2]}>{item.link}</BOHTD>
            <BOHTD width={InitialWidths[3]}>{item.link_page_name}</BOHTD>
            <BOHTD width={InitialWidths[4]}>{item.answer_template}</BOHTD>
            <BOHTDIconBox width={InitialWidths[5]}>
              <TouchableOpacity
                onPress={() => {
                  editBasicQuiz(item);
                }}
              >
                <FontAwesome5 size={TextMediumSize} name="edit" color="black" />
              </TouchableOpacity>
            </BOHTDIconBox>
            <BOHTDIconBox width={InitialWidths[6]}>
              <TouchableOpacity
                onPress={() => {
                  removeBasicQuiz(item.id);
                }}
              >
                <FontAwesome5 size={TextMediumSize} name="times" color="black" />
              </TouchableOpacity>
            </BOHTDIconBox>
          </BOHTR>
        );
      });
    } else {
      <></>;
    }
    
    setIsLoading(false);
    return <>{rows}</>;
  }, [tableData]);

  const tableElement = useMemo(()=>(        
    <BOHTable isLoading={isLoading}>
      <BOHTHead>
        <BOHTR>
          <BOHTH2 width={InitialWidths[0]}>{'Question'}</BOHTH2>
          <BOHTH2 width={InitialWidths[1]}>{'Endpoint'}</BOHTH2>
          <BOHTH2 width={InitialWidths[2]}>{'Link'}</BOHTH2>
          <BOHTH2 width={InitialWidths[3]}>{'Link Label'}</BOHTH2>
          <BOHTH2 width={InitialWidths[4]}>{'Answer Template'}</BOHTH2>
          <BOHTH2 width={InitialWidths[5]}>{'Edit'}</BOHTH2>
          <BOHTH2 width={InitialWidths[6]}>{'DEL'}</BOHTH2>
        </BOHTR>
      </BOHTHead>
      <BOHTBody>
        {renderTableData}
      </BOHTBody>
    </BOHTable>), [isLoading, tableData])

  return (
    <BasicLayout
      navigation={navigation}
      goBack={() => {
        navigation.navigate('AIManager');
      }}
      screenName={'BasicQuiz Manager'}
    >
      <CommonContainer>
        <BOHToolbar>
          <BOHButton
            label="Add"
            onPress={openAddBasicQuizModal}/>
        </BOHToolbar>
        {tableElement}
        <AddBasicQuizModal
          isModalVisible={isAddModalVisible}
          details={selectedBasicQuiz}
          setUpdateBasicQuizzesTrigger={setUpdateBasicQuizzesTrigger}
          closeModal={closeAddBasicQuizModal}
        />
      </CommonContainer>
    </BasicLayout>
  );
};

export default BasicQuizzes;

import ChatBot, { Flow } from "react-chatbotify";
import { settings } from "./settings";
import styles from "./styles.json";
import { chatbotquery, getRecommendsByRandom } from "../../../api/Chatbot";
import { useEffect, useState } from "react";

export const BOHChatbot = () => {
	let hasError = false;
  const [options, setOptions] = useState(null);
console.log(options);
  const fetchStartOptions = async () => {
    try {
      const res = await getRecommendsByRandom();
      const data:any = await res.json();
      setOptions(data.recommends);

    } catch (error) {
      console.error("Error fetching options:", error);
      hasError = true;
    }
  };

  const call_openai = async (params) => {
    try {
      const payload = {
        content: params.userInput,
      }
      const res = await chatbotquery(payload);
      const message = await res.json();
      flow.loop.options = message.recommends
      await params.injectMessage(
        <div className="rcb-bot-message-container">
          <div
            className="rcb-bot-message rcb-bot-message-entry"
            style={{
              display: 'block',
              backgroundColor: "rgb(73, 29, 141)",
              color: "rgb(255, 255, 255)",
              maxWidth: "80%",
            }}
            dangerouslySetInnerHTML={{ __html: message.content }}
          />
        </div>
      );
    } catch (error) {
      await params.injectMessage("Unable to load model, is your API Key valid?");
      hasError = true;
    }
  }
  
  const flow:Flow = {
    start: {
      message: "Hello there! How can I help you?",
      path: "loop",
      options: options,
    },
    loop: {
      message: async (params) => {
        await call_openai(params);
      },
      path: () => {
        if (hasError) {
          return "loop"
        }
        return "loop"
      },
      options: [""]
    }
  }

  useEffect(() => {
    fetchStartOptions();
  }, []);

  return (
    <ChatBot settings={settings} flow={flow} styles={styles} />
  );
};
import React, { useState, useEffect } from 'react';
import {
  Text,
  TextInput,
  TouchableOpacity,
  Modal,
  View,
  ActivityIndicator,
} from 'react-native';

import { createBasicQuiz, updateBasicQuiz } from '../../../api/Chatbot';
import BasicModalContainer from '../../../common/components/basicmodal/BasicModalContainer';
import ModalHeader from '../../../common/components/basicmodal/ModalHeader';
import ModalBody from '../../../common/components/basicmodal/ModalBody';
import ModalFooter from '../../../common/components/basicmodal/ModalFooter';
import { msgStr } from '../../../common/constants/Message';
import { useAlertModal } from '../../../common/hooks';
import { commonModalStyle } from '../../../common/components/basicmodal';
import LabeledTextInput from '../../../common/components/bohform/LabeledTextInput';
import { TextSmallSize } from '../../../common/constants/Fonts';

const AddBasicQuizModal = ({ isModalVisible, details, setUpdateBasicQuizzesTrigger, closeModal }) => {
  const isUpdate = details ? true : false;

  const { showAlert } = useAlertModal();
  const [ValidMessage, setValidMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const [formValues, setFormValues] = useState<any>({
    id: null,
    quiz : null,
    link : null,
    link_page_name : null,
    answer_template : null,
  });

  useEffect(() => {
    if (isModalVisible && details) {
      setFormValues({
        id: details.id,
        quiz : details.quiz,
        link : details.link,
        link_page_name : details.link_page_name,
        answer_template : details.answer_template,
      })
    } else {
      setFormValues({
        id: null,
        quiz : null,
        link : null,
        link_page_name : null,
        answer_template : null,
      })
    }
  }, [isModalVisible]);

  const updateFormValues = (key, value) => {
    setFormValues(prev => ({
      ...prev,
      [key]: value
    }));
  };

  const AddBasicQuizButtonHandler = () => {
    if(!checkInput()) return;

    setIsLoading(true);

    const handleResponse = (jsonRes, status) => {
      switch (status) {
        case 201:
          showAlert('success', jsonRes.message);
          setUpdateBasicQuizzesTrigger(true);
          closeModal();
          break;
        case 409:
          showAlert('error', "This address is already exist");
          break;
        default:
          if (jsonRes && jsonRes.error) showAlert('error', jsonRes.error);
          else showAlert('error', msgStr('unknownError'));
          closeModal();
          break;
      }
      setIsLoading(false);
    };

    if (isUpdate) {
      updateBasicQuiz(formValues, (jsonRes, status) => {
        handleResponse(jsonRes, status);
      });
    } else {
      createBasicQuiz(formValues, (jsonRes, status) => {
        handleResponse(jsonRes, status);
      });
    }
  };

  const checkInput = () => {
    if (!formValues.quiz || !formValues.quiz.trim()) {
      setValidMessage(msgStr('emptyField'));
      return false;
    } else {
      setValidMessage('');
      return true;
    }
  };

  return (
    <Modal
      animationType="none"
      transparent={true}
      visible={isModalVisible}
    >
      <BasicModalContainer>
        <ModalHeader label={isUpdate ? 'Update' : 'Add' + 'Address'} closeModal={closeModal} />
        <ModalBody>
          <View >
            <LabeledTextInput
              label='Question'
              width={500}
              placeholder='Question'
              placeholderTextColor="#ccc"
              value={formValues.quiz || ''}
              onChangeText={val=>updateFormValues('quiz', val)}
              onBlur={checkInput}
            />
            {ValidMessage.trim() != '' && <Text style={styles.message}>{ValidMessage}</Text>}
            <LabeledTextInput
              label='Link'
              width={500}
              placeholder='Link'
              placeholderTextColor="#ccc"
              value={formValues.link || ''}
              onChangeText={val=>updateFormValues('link', val)}
              onBlur={checkInput}
            />
            <LabeledTextInput
              label='Link Label'
              width={500}
              placeholder='Link Label'
              placeholderTextColor="#ccc"
              value={formValues.link_page_name || ''}
              onChangeText={val=>updateFormValues('link_page_name', val)}
              onBlur={checkInput}
            />
            <LabeledTextInput
              label='Answer Template'
              width={500}
              placeholder='Answer Template'
              placeholderTextColor="#ccc"
              inputStyle={{height:120}}
              multiline={true}
              value={formValues.answer_template || ''}
              onChangeText={val=>updateFormValues('answer_template', val)}
              onBlur={checkInput}
            />
            <Text style={[styles.label, { marginBottom: 8, color:'black', fontSize:TextSmallSize }]} selectable={true}>
              {`Available Tokens \nPlease use [result] and [link]`}
            </Text>
          </View>
        </ModalBody>
        <ModalFooter>
          <TouchableOpacity onPress={AddBasicQuizButtonHandler}>
            <Text style={styles.addButton}>{isUpdate ? 'Update' : 'Add'}</Text>
          </TouchableOpacity>
        </ModalFooter>
      </BasicModalContainer>
      {isLoading && (
        <View style={styles.overlay}>
          <ActivityIndicator size="large" color="#0000ff" />
        </View>
      )}
    </Modal>
  );
};

const styles = commonModalStyle;

export default AddBasicQuizModal;

import React, { useEffect, useMemo, useState } from 'react';
import { View, Text, Platform, TouchableOpacity } from 'react-native';
import { FontAwesome5 } from '@expo/vector-icons';
import AsyncStorage from '@react-native-async-storage/async-storage';

import { getReservationsCounts, getReservationsData } from '../../api/Reservation';
import { BasicLayout, CommonContainer } from '../../common/components/CustomLayout';
import { BOHTlbCheckbox, BOHTlbRadio, BOHTlbrSearchInput, BOHTlbrSearchPicker, BOHToolbar, renderBOHTlbDatePicker } from '../../common/components/bohtoolbar';
import { BOHTBody, BOHTD, BOHTDIconBox, BOHTH, BOHTHead, BOHTR, BOHTable } from '../../common/components/bohtable';
import { msgStr } from '../../common/constants/Message';
import { TextdefaultSize, TextMediumSize } from '../../common/constants/Fonts';
import { useAlertModal } from '../../common/hooks';
import { formatDate, formatDate2 } from '../../common/utils/DateUtils';

import { Map, AdvancedMarker, Pin, InfoWindow } from '@vis.gl/react-google-maps';
import { MapPosition } from '../../common/constants/AppConstants';
import { GOOGLE_MAP_Id } from '../../../env';
import { getCurrentStatus } from '../../api/Timeclock';
// import { useSorting } from '../../common/hooks/useSorting';

const Dashboard = ({ navigation }) => {

  const { showAlert } = useAlertModal();

  const [tableData, setTableData] = useState([]);
  const [isloading, setIsLoading] = useState(true);
  const [quantities, setQuantities] = useState({
    provisional:0,
    confirmed:0,
    checkedOut:0,
    checkedIn:0,
    canceled:0,
  });
  const [updateReservationListTrigger, setUpdateReservationListTrigger] = useState(false);
  const [sorting, setSorting] = useState({
    key: null,
    direction: null,
  })

  const [periodRange, setPeriodRange] = useState<any>('');
  const twoWeeksAgo = new Date(Date.now() - 14 * 24 * 60 * 60 * 1000);
  const tomorrow = new Date(Date.now() + 24 * 60 * 60 * 1000);
  const initialWidths = [100, 100, 160, 160, 100, 100, 110, 110, 80, 70];

  const [ searchOptions, setSearchOptions ] = useState({
    start_date : `${twoWeeksAgo.getFullYear()}-${String(twoWeeksAgo.getMonth() + 1).padStart(2, '0')}-${String(twoWeeksAgo.getDate()).padStart(2, '0')}`,
    end_date : `${tomorrow.getFullYear()}-${String(tomorrow.getMonth() + 1).padStart(2, '0')}-${String(tomorrow.getDate()).padStart(2, '0')}`,
    customer : '',
    brand: '',
    order_number: '',
    stage: null,
    status_filter: null,
    hideBeachTennis: false,
    ShowOnlyManual: false,
  });

  const [ MapFilterOptions, setMapFilterOptions ] = useState({
    not_delivered: true,
    clocked_in: true,
    lunch: true,
    break: true,
    delivered: false,
    on_trip: false,
    picked_up: false,
  });

  const [currentStatus, setCurrentStatus] = useState([]);

  useEffect(() => {
    switch (periodRange.toLowerCase()) {
      case 'today':
        setSearchOptions({
          ...searchOptions,
          start_date: new Date().toISOString().substr(0, 10),
          end_date: new Date().toISOString().substr(0, 10),
        });
        break;
      case 'tomorrow':
        setSearchOptions({
          ...searchOptions,
          start_date: new Date(Date.now() + 1 * 24 * 60 * 60 * 1000).toISOString().substr(0, 10),
          end_date: new Date(Date.now() + 1 * 24 * 60 * 60 * 1000).toISOString().substr(0, 10),
        });
        break;
      case 'yesterday':
        setSearchOptions({
          ...searchOptions,
          start_date: new Date(Date.now() - 1 * 24 * 60 * 60 * 1000).toISOString().substr(0, 10),
          end_date: new Date(Date.now() - 1 * 24 * 60 * 60 * 1000).toISOString().substr(0, 10),
        });
        break;
      case 'today+tomorrow':
        setSearchOptions({
          ...searchOptions,
          start_date: new Date().toISOString().substr(0, 10),
          end_date: new Date(Date.now() + 1 * 24 * 60 * 60 * 1000).toISOString().substr(0, 10),
        });
        break;
      case '7days':
        setSearchOptions({
          ...searchOptions,
          start_date: new Date(Date.now() - 7 * 24 * 60 * 60 * 1000).toISOString().substr(0, 10),
          end_date: new Date().toISOString().substr(0, 10),
        });
        break;
      default:
        break;
    }
  }, [periodRange]);

  useEffect(()=>{
    loadSearchOption();
    getCurrentStatus((jsonRes, status)=>{
      if(jsonRes){
        setCurrentStatus(jsonRes);
      }else setCurrentStatus([]);
    })
  }, [])
  
  const loadSearchOption = async () => {
    const [cachedSearchOptions, cachedTimestamp] = await Promise.all([
      AsyncStorage.getItem('__search_options'),
      AsyncStorage.getItem('__search_options_timestamp')
    ]);
    if (cachedTimestamp && cachedSearchOptions &&(new Date().getTime() - parseInt(cachedTimestamp, 10)) < 600000 ) {
      setSearchOptions(JSON.parse(cachedSearchOptions));
    } else {
      AsyncStorage.removeItem('__search_options');
    }
  }

  useEffect(()=>{
    const timeout = setTimeout(() => {
      AsyncStorage.setItem('__search_options', JSON.stringify(searchOptions))
      AsyncStorage.setItem('__search_options_timestamp', new Date().getTime().toString())
      setUpdateReservationListTrigger(true)
    }, 300);
    return () => clearTimeout(timeout);
  }, [searchOptions])

  useEffect(() => {
    const today = new Date();
    switch (periodRange.toLowerCase()) {
      case 'today':
        setSearchOptions({
          ...searchOptions,
          start_date: formatDate(new Date()),
          end_date: formatDate(new Date()),
        });
        break;
      case 'tomorrow':
        setSearchOptions({
          ...searchOptions,
          start_date: formatDate(new Date(Date.now() + 1 * 24 * 60 * 60 * 1000)),
          end_date: formatDate(new Date(Date.now() + 1 * 24 * 60 * 60 * 1000)),
        });
        break;
      case 'yesterday':
        setSearchOptions({
          ...searchOptions,
          start_date: formatDate(new Date(Date.now() - 1 * 24 * 60 * 60 * 1000)),
          end_date: formatDate(new Date(Date.now() - 1 * 24 * 60 * 60 * 1000)),
        });
        break;
      case 'today+tomorrow':
        setSearchOptions({
          ...searchOptions,
          start_date: formatDate(new Date()),
          end_date: formatDate(new Date(Date.now() + 1 * 24 * 60 * 60 * 1000)),
        });
        break;
      case '7days':
        setSearchOptions({
          ...searchOptions,
          start_date: formatDate(new Date(Date.now() - 7 * 24 * 60 * 60 * 1000)),
          end_date: formatDate(new Date()),
        });
      break;
      case 'next fri':
        let nextFriday = new Date(today);
        nextFriday.setDate(nextFriday.getDate() + (5 + 7 - nextFriday.getDay()) % 7);
        if (nextFriday.getTime() === today.getTime()) {
          nextFriday.setDate(nextFriday.getDate() + 7);
        }
        setSearchOptions({
          ...searchOptions,
          start_date: formatDate(nextFriday),
          end_date: formatDate(nextFriday),
        });
        break;
      case 'next sat':
        let nextSaturday = new Date(today);
        nextSaturday.setDate(nextSaturday.getDate() + (6 + 7 - nextSaturday.getDay()) % 7);
        setSearchOptions({
          ...searchOptions,
          start_date: formatDate(nextSaturday),
          end_date: formatDate(nextSaturday),
        });
        break;
      case 'next sun':
        let nextSunday = new Date(today);
        nextSunday.setDate(nextSunday.getDate() + (7 + 7 - nextSunday.getDay()) % 7);
        setSearchOptions({
          ...searchOptions,
          start_date: formatDate(nextSunday),
          end_date: formatDate(nextSunday),
        });
        break;
      case 'next mon':
        let nextMonday = new Date(today);
        nextMonday.setDate(nextMonday.getDate() + (1 + 7 - nextMonday.getDay()) % 7);
        setSearchOptions({
          ...searchOptions,
          start_date: formatDate(nextMonday),
          end_date: formatDate(nextMonday),
        });
        break;
      default:
        break;
    }
  }, [periodRange]);

  useEffect(() => {
    if (searchOptions.start_date && searchOptions.end_date) {
      if (searchOptions.start_date === formatDate(new Date()) &&
          searchOptions.end_date === formatDate(new Date())) {
        if(periodRange != 'Today') setPeriodRange('Today');
      } else if (searchOptions.start_date === formatDate(new Date(Date.now() + 1 * 24 * 60 * 60 * 1000)) &&
                  searchOptions.end_date === formatDate(new Date(Date.now() + 1 * 24 * 60 * 60 * 1000))) {
        if(periodRange != 'Tomorrow') setPeriodRange('Tomorrow');
      } else if (searchOptions.start_date === formatDate(new Date(Date.now() - 1 * 24 * 60 * 60 * 1000)) &&
                  searchOptions.end_date === formatDate(new Date(Date.now() - 1 * 24 * 60 * 60 * 1000))) {
        if(periodRange != 'Yesterday') setPeriodRange('Yesterday');
      } else if (searchOptions.start_date === formatDate(new Date()) &&
                  searchOptions.end_date === formatDate(new Date(Date.now() + 1 * 24 * 60 * 60 * 1000))) {
        if(periodRange != 'Today+Tomorrow') setPeriodRange('Today+Tomorrow');
      } else if (searchOptions.start_date === formatDate(new Date(Date.now() - 7 * 24 * 60 * 60 * 1000)) &&
                  searchOptions.end_date === formatDate(new Date())) {
        if(periodRange != '7days') setPeriodRange('7days');
      } else if (searchOptions.start_date == searchOptions.end_date && new Date(searchOptions.start_date).getDay() === 4 
                && (new Date(searchOptions.start_date).getTime() - new Date().getTime())/86400000 < 7) {
        if(periodRange != 'Next Fri') setPeriodRange('Next Fri');
      } else if (searchOptions.start_date == searchOptions.end_date && new Date(searchOptions.start_date).getDay() === 5 
                && (new Date(searchOptions.start_date).getTime() - new Date().getTime())/86400000 < 7) {
        if(periodRange != 'Next Sat') setPeriodRange('Next Sat');
      } else if (searchOptions.start_date == searchOptions.end_date && new Date(searchOptions.start_date).getDay() === 6 
                && (new Date(searchOptions.start_date).getTime() - new Date().getTime())/86400000 < 7) {
        if(periodRange != 'Next Sun') setPeriodRange('Next Sun');
      } else if (searchOptions.start_date == searchOptions.end_date && new Date(searchOptions.start_date).getDay() === 0 
                && (new Date(searchOptions.start_date).getTime() - new Date().getTime())/86400000 < 7) {
        if(periodRange != 'Next Mon') setPeriodRange('Next Mon');
      } else {
        if(periodRange != 'custom') setPeriodRange('custom');
      }
    }
  }, [searchOptions.start_date, searchOptions.end_date]);

  const stage = [
    'draft',
    'provisional',
    'confirmed',
    'checked out',
    'checked in',
    'canceled',
  ];

  const [showInfoWindow, setShowInfoWindow] = useState(false);
  const [InfoWindowDetail, setInfoWidowDetail] = useState({
    lat: null,
    lng: null,
    name: '',
  })

  const openInfoWindow = (log) => {
    setInfoWidowDetail({lat: log.lat, lng:log.lng, name: log.name})
    setShowInfoWindow(true);
  };

  const closeInfoWindow = () => {
    setInfoWidowDetail({lat: null, lng:null, name: null})
    setShowInfoWindow(false);
  };

  const changeSearchOptions = (key, val) => {
    setSearchOptions(prevOptions => ({
      ...prevOptions,
      [key]: val
    }));
  }

  const changeMapFilterOptions = (key, val) => {
    setMapFilterOptions(prevOptions => ({
      ...prevOptions,
      [key]: val
    }));
  }

  useEffect(() => {
    if (updateReservationListTrigger == true) getTable();
  }, [updateReservationListTrigger]);

  const getTable = () => {
    setIsLoading(true);
    getReservationsData({searchOptions:searchOptions}, (jsonRes, status, error) => {
      switch (status) {
        case 200:
          setUpdateReservationListTrigger(false);
          setTableData(jsonRes);
          break;
        case 500:
          showAlert('error', msgStr('serverError'));
          break;
        default:
          if (jsonRes && jsonRes.error) showAlert('error', jsonRes.error);
          else showAlert('error', msgStr('unknownError'));
          break;
      }
    });
    getReservationsCounts({searchOptions:searchOptions}, (jsonRes, status, error) => {
      const quantities = {
        provisional:0,
        confirmed:0,
        checkedOut:0,
        checkedIn:0,
        canceled:0,
      }

      if(status == 200){
        jsonRes.map(item=>{
          if(item.stage == 1) quantities.provisional = item.amounts;
          else if(item.stage == 2) quantities.confirmed = item.amounts;
          else if(item.stage == 3) quantities.checkedOut = item.amounts;
          else if(item.stage == 4) quantities.checkedIn = item.amounts;
          else if(item.stage == 5) quantities.canceled = item.amounts;
        })
      }

      setQuantities(quantities);
    })
  };
  // const { sortedData, handleSort, sorting } = useSorting('dashboard', tableData, { key: 'order_number', direction: 'ascending' });

  const handleSort = (key:string) => {
    const sortsUpdate = {
      key: key,
      direction: sorting.key == key && sorting.direction == 'ascending' ? 'descending' : 'ascending'
    }
    setSorting(sortsUpdate)
  }

  const renderTableData = useMemo(() => {

    if(sorting.key) 
    tableData.sort((a, b) => {
      if (a[sorting.key] < b[sorting.key]) return sorting.direction === 'ascending' ? -1 : 1;
      if (a[sorting.key] > b[sorting.key]) return sorting.direction === 'ascending' ? 1 : -1;
      return 0;
    });

    const convertStageToString = (stage) => {
      switch (stage) {
        case null: case 'null': return 'Draft';
        case 0: case '0': return 'Draft';
        case 1: case '1': return 'Provisional';
        case 2: case '2': return 'Confirmed';
        case 3: case '3': return 'Checked out';
        case 4: case '4': return 'Checked in';
        case 5: case '5': return 'Canceled';
        default:  return 'Invalid stage';
      }
    }

    const rows = [];
    if (tableData.length > 0) {
      tableData.map((item, index) => {
        let wIndex = 0;
        rows.push(
          <BOHTR key={index}>
            <BOHTD width={initialWidths[wIndex++]}>{item.order_number}</BOHTD>
            <BOHTD width={initialWidths[wIndex++]}>{formatDate2(new Date(item.createdAt))}</BOHTD>
            <BOHTD width={initialWidths[wIndex++]}>{item.brand}</BOHTD>
            <BOHTD width={initialWidths[wIndex++]}>{item.full_name}</BOHTD>
            <BOHTD width={initialWidths[wIndex++]}>{item.start_date ? formatDate2(new Date(`${item.start_date} 00:00:00`)) : ''}</BOHTD>
            <BOHTD width={initialWidths[wIndex++]}>{item.end_date ? formatDate2(new Date(`${item.end_date} 00:00:00`)) : ''}</BOHTD>
            <BOHTD width={initialWidths[wIndex++]} textAlign={'right'}>{item?.quantity??''}</BOHTD>
            <BOHTD width={initialWidths[wIndex++]}>{convertStageToString(item.stage)}</BOHTD>
            <BOHTD width={initialWidths[wIndex++]} textAlign={'center'}>{item?.color_id?'YES':'NO'}</BOHTD>
            <BOHTDIconBox width={initialWidths[wIndex++]}>
              <TouchableOpacity
                onPress={() => {
                  navigation.navigate('ReservationDetail', {
                    reservationId: item.id,
                    back: 'Dashboard'
                  });
                }}
              >
                <FontAwesome5 size={TextMediumSize} name="arrow-right" color="black" />
              </TouchableOpacity>
            </BOHTDIconBox>
          </BOHTR>
        );
      });
    } else {
      <></>;
    }
    
    setIsLoading(false);
    return <>{rows}</>;
  }, [tableData, sorting]);

  const tableElement = useMemo(()=>{
    let wIndex = 0;
    const headers = [
      { key: 'order_number', label: 'Order #' },
      { key: 'createdAt', label: 'Created' },
      { key: 'brand', label: 'Brand' },
      { key: 'full_name', label: 'Customer' },
      { key: 'start_date', label: 'Start' },
      { key: 'end_date', label: 'End' },
      { key: 'quantity', label: 'Qty of bikes' },
      { key: 'stage', label: 'Stage' },
      { key: 'color_id', label: 'Locked' },
      { key: 'detail', label: 'Detail' },
    ];
    return (<BOHTable isLoading={isloading}>
      <BOHTHead>
        <BOHTR>
          {headers.map(({ key, label }) => (
            <BOHTH
              key={key}
              width={initialWidths[wIndex++]}
              isSorted={sorting.key === key}
              direction={sorting.key === key ? sorting.direction : null}
              onClick={() => handleSort(key)}
            >
              {label}
            </BOHTH>
          ))}
        </BOHTR>
      </BOHTHead>
      <BOHTBody>
        {renderTableData}
      </BOHTBody>
    </BOHTable>)}, [isloading, tableData, sorting])

  const mapElement = useMemo(()=>(
    <View style={{height:'100%', paddingLeft:30}}>
      {Platform.OS == 'web' && 
        <div style={{minWidth:'90vh', height:'100%'}}>
          <Map
            defaultCenter={MapPosition}
            defaultZoom={13.5}
            gestureHandling={'greedy'}
            disableDefaultUI={true}
            mapId={GOOGLE_MAP_Id}
          >
            {currentStatus.map((log, index) => {
              if (log.lat && log.lng && log.status > 0) {
                let filterOption;
                let pinColor = {
                  background: "#33adff",
                  borderColor: 'green',
                  glyphColor: 'white',
                };
                if (log.status === 1 && MapFilterOptions.clocked_in) {
                  filterOption = 'clocked_in'
                  pinColor = {
                    background: "#33adff",
                    borderColor: 'green',
                    glyphColor: 'white',
                  }
                } else if (log.status === 2 && MapFilterOptions.lunch) {
                  filterOption = 'lunch'
                  pinColor = {
                    background: "#F0AD4E",
                    borderColor: '#D9534F',
                    glyphColor: '#D9534F',
                  }
                } else if (log.status === 3 && MapFilterOptions.break) {
                  filterOption = 'break'
                  pinColor = {
                    background: "#F0AD4E",
                    borderColor: '#ff8533',
                    glyphColor: '#4CAF50',
                  }
                }
                return (
                  <div key={index} >
                    <AdvancedMarker 
                      position={{ lat: log.lat, lng: log.lng }} 
                      onClick={() =>{openInfoWindow(log)}}
                      style={{display:MapFilterOptions[filterOption]?'block':'none'}}
                    >
                      <Pin {...pinColor}/>
                    </AdvancedMarker>
                  </div>
                );
              }
            })}
            {tableData.map((reservation, index) => {
              let lat = reservation?.lat ?? reservation?.geolat ?? null;
              let lng = reservation?.lng ?? reservation?.geolong ?? null;
              if (lat && lng) {
                let filterOption;
                let pinColor = {
                  background: "#33adff",
                  borderColor: 'green',
                  glyphColor: 'white',
                };
                if (reservation.stage == 4) { // picked up
                  filterOption = 'picked_up'
                  pinColor = {
                    background: "blue",
                    borderColor: 'blue',
                    glyphColor: 'red',
                  };
                } else if (reservation.stage == 3) { // delivered
                  filterOption = 'delivered'
                  pinColor = {
                    background: "green",
                    borderColor: 'green',
                    glyphColor: 'red',
                  };
                } else if (reservation.trip_released == 1 && reservation.trip_complete == 0) {
                  filterOption = 'on_trip'
                  pinColor = {
                    background: "yellow",
                    borderColor: 'yellow',
                    glyphColor: 'red',
                  };
                } else if (reservation.stage == 2) {
                  filterOption = 'not_delivered'
                  pinColor = {
                    background: "purple",
                    borderColor: 'purple',
                    glyphColor: 'red',
                  };
                }
                return (
                  <div key={index} >
                    <AdvancedMarker
                      position={{ lat: reservation.lat, lng: reservation.lng }} 
                      onClick={() =>{openInfoWindow({name:reservation.order_number, lat, lng})}}
                      style={{display:MapFilterOptions[filterOption]?'block':'none'}}
                    >
                      <Pin {...pinColor}/>
                    </AdvancedMarker>
                  </div>
                );
              }
            })}
            {showInfoWindow && InfoWindowDetail.lat && 
            <InfoWindow position={{ lat: InfoWindowDetail.lat, lng: InfoWindowDetail.lng }} pixelOffset={[0, -30]} headerDisabled={true}>
              {InfoWindowDetail.name}
              <span 
                style={{ marginLeft: 15, fontSize: 20, cursor: 'pointer' }} 
                onClick={closeInfoWindow}
              >
                &times;
              </span>
            </InfoWindow>}
          </Map>
        </div>}
    </View>), [currentStatus, showInfoWindow, InfoWindowDetail, MapFilterOptions, tableData])

  return (
    <BasicLayout
      navigation={navigation}
      screenName={'Dashboard'}
    >
      <CommonContainer style={{flexDirection:'row'}}>
        <View style={{alignItems:'flex-start', height:'100%'}}>
          <BOHToolbar style={{zIndex:100}}>
            <Text style={{marginRight:8, fontSize:TextdefaultSize}}>Start</Text>
            {Platform.OS == 'web' && 
              renderBOHTlbDatePicker(searchOptions.start_date, (date) => {
                const year = date.getFullYear();
                const formattedDate = `${year}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`;
                changeSearchOptions('start_date', formattedDate);
            })}
            <Text style={{marginHorizontal:8, fontSize:TextdefaultSize}}>End</Text>
            {Platform.OS == 'web' && 
              renderBOHTlbDatePicker(searchOptions.end_date, (date) => {
                const year = date.getFullYear();
                const formattedDate = `${year}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`;
                changeSearchOptions('end_date', formattedDate);
            })}
            <BOHTlbRadio
              label='Today'
              onPress={()=>{setPeriodRange('Today')}}
              RadioButtonProps={{
                value: '1',
                status: periodRange == 'Today'? 'checked': 'unchecked',
              }}
            />
            <BOHTlbRadio
              label='Tomorrow'
              onPress={()=>{setPeriodRange('Tomorrow')}}
              RadioButtonProps={{
                value: '1',
                status: periodRange == 'Tomorrow'? 'checked': 'unchecked',
              }}
            />
            <BOHTlbRadio
              label='Yesterday'
              onPress={()=>{setPeriodRange('Yesterday')}}
              RadioButtonProps={{
                value: '1',
                status: periodRange == 'Yesterday'? 'checked': 'unchecked',
              }}
            />
            <BOHTlbRadio
              label='Today+Tomorrow'
              onPress={()=>{setPeriodRange('Today+Tomorrow')}}
              RadioButtonProps={{
                value: '1',
                status: periodRange == 'Today+Tomorrow'? 'checked': 'unchecked',
              }}
            />
            <BOHTlbRadio
              label='7 days'
              onPress={()=>{setPeriodRange('7days')}}
              RadioButtonProps={{
                value: '1',
                status: periodRange == '7days'? 'checked': 'unchecked',
              }}
            />
          </BOHToolbar>
          <BOHToolbar>
            <BOHTlbRadio
              label='Next Fri'
              style={{margin:0}}
              onPress={()=>{setPeriodRange('Next Fri')}}
              RadioButtonProps={{
                value: '1',
                status: periodRange == 'Next Fri'? 'checked': 'unchecked',
              }}
            />
            <BOHTlbRadio
              label='Next Sat'
              onPress={()=>{setPeriodRange('Next Sat')}}
              RadioButtonProps={{
                value: '1',
                status: periodRange == 'Next Sat'? 'checked': 'unchecked',
              }}
            />
            <BOHTlbRadio
              label='Next Sun'
              onPress={()=>{setPeriodRange('Next Sun')}}
              RadioButtonProps={{
                value: '1',
                status: periodRange == 'Next Sun'? 'checked': 'unchecked',
              }}
            />
            <BOHTlbRadio
              label='Next Mon'
              onPress={()=>{setPeriodRange('Next Mon')}}
              RadioButtonProps={{
                value: '1',
                status: periodRange == 'Next Mon'? 'checked': 'unchecked',
              }}
            />
          </BOHToolbar>
          <BOHToolbar>
            <BOHTlbRadio
              label={`Checked In (${quantities.checkedIn})`}
              style={{margin:0}}
              onPress={()=>{
                changeSearchOptions('stage', null);
                changeSearchOptions('status_filter', 1)
              }}
              RadioButtonProps={{
                value: '1',
                status: searchOptions.status_filter == 1? 'checked': 'unchecked',
                color: '#ff4d4d',
              }}
            />
            <BOHTlbRadio
              label={`Checked Out (${quantities.checkedOut})`}
              onPress={()=>{
                changeSearchOptions('stage', null);
                changeSearchOptions('status_filter', 2)
              }}
              RadioButtonProps={{
                value: '1',
                status: searchOptions.status_filter == 2? 'checked': 'unchecked',
                color: '#ff4d4d',
              }}
            />
            <BOHTlbRadio
              label={`Provisional (${quantities.provisional})`}
              onPress={()=>{
                changeSearchOptions('stage', null);
                changeSearchOptions('status_filter', 3)
              }}
              RadioButtonProps={{
                value: '1',
                status: searchOptions.status_filter == 3? 'checked': 'unchecked',
                color: '#ff4d4d',
              }}
            />
            <BOHTlbRadio
              label={`Confirmed (${quantities.confirmed})`}
              onPress={()=>{
                changeSearchOptions('stage', null);
                changeSearchOptions('status_filter', 4)
              }}
              RadioButtonProps={{
                value: '1',
                status: searchOptions.status_filter == 4? 'checked': 'unchecked',
                color: '#ff4d4d',
              }}
            />
            <BOHTlbRadio
              label={`Canceled (${quantities.canceled})`}
              onPress={()=>{
                changeSearchOptions('stage', null);
                changeSearchOptions('status_filter', 5)
              }}
              RadioButtonProps={{
                value: '1',
                status: searchOptions.status_filter == 5? 'checked': 'unchecked',
                color: '#ff4d4d',
              }}
            />
            <BOHTlbRadio
              label={`All (${quantities.checkedIn + quantities.checkedOut + quantities.provisional + quantities.confirmed})`}
              style={{opacity: searchOptions.status_filter?1:0,}}
              onPress={()=>{
                changeSearchOptions('stage', null);
                changeSearchOptions('status_filter', null)
              }}
              RadioButtonProps={{
                value: '1',
                status: searchOptions.status_filter == null? 'checked': 'unchecked',
                color: '#ff4d4d',
              }}
            />
          </BOHToolbar>
          <BOHToolbar style={{width: '100%', justifyContent:'space-between'}}>
            <BOHTlbrSearchInput
              boxStyle={{margin:0}}
              width={125}
              label='Customer'
              defaultValue={searchOptions.customer}
              onChangeText={(val)=>changeSearchOptions('customer', val)}
            />
            <BOHTlbrSearchInput
              boxStyle={{margin:0}}
              width={125}
              label='Brand'
              defaultValue={searchOptions.brand}
              onChangeText={(val)=>changeSearchOptions('brand', val)}
            />
            <BOHTlbrSearchInput
              boxStyle={{margin:0}}
              width={125}
              label='Order number'
              defaultValue={searchOptions.order_number}
              onChangeText={(val)=>changeSearchOptions('order_number', val)}
            />
            <BOHTlbrSearchPicker
              width={125}
              boxStyle={{margin:0}}
              enabled={searchOptions.status_filter?false:true}
              label="Status"
              items={[
                {label: '', value: ''}, 
                ...stage
                  .map((item, index) => {
                    if (index === 2 || index === 3 || index === 4 || index === 5) {
                      return {label: item, value: index};
                    } else {
                      return null;
                    }
                  })
                  .filter(item => item !== null)
              ]}
              selectedValue={searchOptions.stage || ''}
              onValueChange={val=>changeSearchOptions('stage', val)}/>
          </BOHToolbar>
          <BOHToolbar>
            <BOHTlbCheckbox
              label={'Hide beach and tennis'}
              style={{marginRight:10}}
              CheckboxProps={{
                value:searchOptions.hideBeachTennis
              }}
              onPress={()=>{
                changeSearchOptions('hideBeachTennis', !searchOptions.hideBeachTennis);
              }}
            />
            <BOHTlbCheckbox
              label={'Show only manual addresses'}
              CheckboxProps={{
                value:searchOptions.ShowOnlyManual
              }}
              onPress={()=>{
                changeSearchOptions('ShowOnlyManual', !searchOptions.ShowOnlyManual);
              }}
            />
            <Text style={{marginLeft:50, fontSize:TextdefaultSize}}>{`Total #:  `}{tableData?.length??0}</Text>
          </BOHToolbar>
          {tableElement}
        </View>
        <View style={{flexDirection:'column', height:'100%'}}>
          <BOHToolbar>
            <BOHTlbCheckbox
              label={'Clocked In'}
              style={{ marginLeft: 30 }}
              CheckboxProps={{
                value: MapFilterOptions.clocked_in,
                color: '#FF5900'
              }}
              onPress={() => {
                changeMapFilterOptions('clocked_in', !MapFilterOptions.clocked_in);
              }}
            />
            <BOHTlbCheckbox
              label={'Break'}
              CheckboxProps={{
                value: MapFilterOptions.break,
                color: '#FF5900'
              }}
              onPress={() => {
                changeMapFilterOptions('break', !MapFilterOptions.break);
              }}
            />
            <BOHTlbCheckbox
              label={'Lunch'}
              CheckboxProps={{
                value: MapFilterOptions.lunch,
                color: '#FF5900'
              }}
              onPress={() => {
                changeMapFilterOptions('lunch', !MapFilterOptions.lunch);
              }}
            />
          {/* </BOHToolbar>
          <BOHToolbar> */}
            <BOHTlbCheckbox
              label={'Not Delivered'}
              style={{ marginLeft: 30 }}
              CheckboxProps={{
                value: MapFilterOptions.not_delivered
              }}
              onPress={() => {
                changeMapFilterOptions('not_delivered', !MapFilterOptions.not_delivered);
              }}
            />
            <BOHTlbCheckbox
              label={'On trip'}
              CheckboxProps={{
                value: MapFilterOptions.on_trip
              }}
              onPress={() => {
                changeMapFilterOptions('on_trip', !MapFilterOptions.on_trip);
              }}
            />
            <BOHTlbCheckbox
              label={'Delivered'}
              CheckboxProps={{
                value: MapFilterOptions.delivered
              }}
              onPress={() => {
                changeMapFilterOptions('delivered', !MapFilterOptions.delivered);
              }}
            />
            <BOHTlbCheckbox
              label={'Picked up'}
              CheckboxProps={{
                value: MapFilterOptions.picked_up
              }}
              onPress={() => {
                changeMapFilterOptions('picked_up', !MapFilterOptions.picked_up);
              }}
            />
          </BOHToolbar>
          <View style={{flex:1}}>
            {mapElement}
          </View>
        </View>
      </CommonContainer>
    </BasicLayout>
  );
};

export default Dashboard;

import React, { useEffect, useMemo, useState } from 'react';
import { Text, Platform, View, Button, TouchableOpacity } from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { FontAwesome5 } from '@expo/vector-icons';

import { getBikeStandSales } from '../../../api/Reports';
import { BasicLayout, CommonContainer } from '../../../common/components/CustomLayout';
import { BOHTBody, BOHTD, BOHTH2, BOHTHead, BOHTR, BOHTable } from '../../../common/components/bohtable';
import { msgStr } from '../../../common/constants/Message';
import { useAlertModal } from '../../../common/hooks';
import { BOHToolbar, renderBOHTlbDatePicker } from '../../../common/components/bohtoolbar';
import { TextdefaultSize, TextMediumSize } from '../../../common/constants/Fonts';
import { formatDate, getStartEndDates } from '../../../common/utils/DateUtils';

const BikeStandSalesReport = ({ navigation }) => {
  const { showAlert } = useAlertModal();

  const [tableData, setTableData] = useState([]);
  const [updateLocationTrigger, setUpdateLocationsTrigger] = useState(false);
  const InitialWidths = [150, 150, 150, 150];
  const [isLoading, setLoading] = useState(false);

  const today = new Date();

  const [searchOptions, setSearchOptions] = useState({
    start_date: formatDate(getStartEndDates(today).start),
    end_date: formatDate(getStartEndDates(today).end),
  });

  const changeSearchOptions = (key, val) => {
    setSearchOptions(prevOptions => ({
      ...prevOptions,
      [key]: val
    }));
  }

  useEffect(() => {
    setLoading(true);
    setUpdateLocationsTrigger(true);
  }, [searchOptions])

  useEffect(() => {
    if (updateLocationTrigger == true) getTable();
  }, [updateLocationTrigger]);


  useEffect(() => {
    const interval = setInterval(() => {
      getTable();
    }, 20 * 1000);

    return () => clearInterval(interval);
  }, []);

  const getTable = () => {
    setLoading(true);
    getBikeStandSales({ searchOptions }, (jsonRes, status, error) => {
      switch (status) {
        case 200:
          setTableData(jsonRes);
          setUpdateLocationsTrigger(false);
          break;
        case 500:
          showAlert('error', msgStr('serverError'));
          break;
        default:
          if (jsonRes && jsonRes.error) showAlert('error', jsonRes.error);
          else showAlert('error', msgStr('unknownError'));
          break;
      }
      setLoading(false);
    });
  };
  
  const openDetail = (searchOptions) => {
    console.log(searchOptions);
    AsyncStorage.setItem('__logdetail_options', JSON.stringify(searchOptions))

    if (Platform.OS == 'web') {
      window.open('/home/logdetail');
    }
  }  

  const changeWeek = (direction) => {
    const newDate = new Date(searchOptions.end_date);
    newDate.setDate(newDate.getDate() + (direction === 'next' ? 7 : -7));
    setSearchOptions({
      start_date: formatDate(getStartEndDates(newDate).start),
      end_date: formatDate(getStartEndDates(newDate).end),
    });
  };

  const renderTableData = () => {
            
    // if(sorting.key) 
    // tableData.sort((a, b) => {
    //   if (a[sorting.key] < b[sorting.key]) return sorting.direction === 'ascending' ? -1 : 1;
    //   if (a[sorting.key] > b[sorting.key]) return sorting.direction === 'ascending' ? 1 : -1;
    //   return 0;
    // });

    const rows = [];
    if (tableData && tableData.length && tableData.length > 0) {
      tableData.map((item, index) => {
        rows.push(
          <BOHTR key={index}>
            <BOHTD width={InitialWidths[0]}>{item.formattedDate}</BOHTD>
            <BOHTD width={InitialWidths[1]}>{item.weekdayShort}</BOHTD>
            <BOHTD width={InitialWidths[2]}>{item.subtotal}</BOHTD>
            <BOHTD width={InitialWidths[3]}>{item.driver_tip}</BOHTD>
          </BOHTR>
        );
      });
    } else {
      <></>;
    }
    return <>{rows}</>;
  };

  const headers = [
    { key: 'Date', label: 'Date' },
    { key: 'WeekDays', label: 'Week Days' },
    { key: 'sale', label: '$' },
    { key: 'driver_tip', label: 'Driver Tip' },
  ];

  const tableElement = useMemo(() => (
    <BOHTable style={{flex:'none'}}>
      <BOHTHead>
        <BOHTR>
          {headers.map(({ key, label }, wIndex) => (
            <BOHTH2
              key={key}
              width={InitialWidths[wIndex]}
            >
              {label}
            </BOHTH2>
          ))}
        </BOHTR>
      </BOHTHead>
      <BOHTBody>
        {renderTableData()}
      </BOHTBody>
    </BOHTable>
  ), [tableData]);
  return (
    <BasicLayout
      navigation={navigation}
      goBack={() => {
        navigation.navigate('Reports');
      }}
      screenName={'Bike Stand Sales Report'}
      isLoading={isLoading}
    >
      <CommonContainer>
        <BOHToolbar style={{ zIndex: 10 }}>
          <TouchableOpacity onPress={() => {changeWeek('prev');}} style={{marginRight:16}}>
            <FontAwesome5 size={TextMediumSize} name="chevron-left" color="black" />
          </TouchableOpacity>
          <Text style={{ marginRight: 8, fontSize: TextdefaultSize }}>Start</Text>
          {Platform.OS == 'web' &&
            renderBOHTlbDatePicker(searchOptions.start_date, (date) => {
              const year = date.getFullYear();
              const formattedDate = `${year}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`;
              changeSearchOptions('start_date', formattedDate);
            }, {}, {disabled:true})}
          <Text style={{ marginHorizontal: 8, fontSize: TextdefaultSize }}>End</Text>
          {Platform.OS == 'web' &&
            renderBOHTlbDatePicker(searchOptions.end_date, (date) => {
              const year = date.getFullYear();
              const formattedDate = `${year}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`;
              changeSearchOptions('end_date', formattedDate);
            }, {}, {disabled:true})}
          <TouchableOpacity onPress={() => {changeWeek('next');}} style={{marginLeft:16}}>
            <FontAwesome5 size={TextMediumSize} name="chevron-right" color="black" />
          </TouchableOpacity>
        </BOHToolbar>
        {tableElement}
      </CommonContainer>
    </BasicLayout>
  );
};

export default BikeStandSalesReport;
